import { css } from '@emotion/core';
import { breakpoint, ScreenWidth } from '../../global.style';

export const container = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoint(ScreenWidth.SM)]: {
        width: '700px',
    },
    [breakpoint(ScreenWidth.MD)]: {
        width: '900px',
    },
    [breakpoint(ScreenWidth.LG)]: {
        width: '1220px',
    },
});
