import React from 'react';
import { Link } from 'gatsby';
import { container, underline } from './index.style';

interface Props {
    to: string;
    selected?: boolean;
    children: React.ReactNode;
}

export default function HeaderLink(props: Props) {
    const { to, selected, children } = props;
    return (
        <div css={container}>
            <Link to={to}>{children}</Link>
            {selected && <div data-testid="active-underline" css={underline} />}
        </div>
    );
}
