import { css } from '@emotion/core';
import { breakpoint, ScreenWidth } from '../../global.style';

export const header = css({
    marginBottom: '0.1rem',
    display: 'flex',
    flexDirection: 'column',
});

export const innerContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoint(ScreenWidth.SM)]: {
        display: 'flex',
        flexDirection: 'row',
    },
});

export const logo = css({
    width: '100px',
    marginLeft: '10px',
    marginTop: '36px',
    [breakpoint(ScreenWidth.XS)]: {
        marginTop: '36px',
        marginLeft: '36px',
        width: '162px',
        height: '59px',
    },
});

export const links = css({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '36px',
    [breakpoint(ScreenWidth.XS)]: {
        marginTop: '53px',
    },
});
