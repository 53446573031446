import React from 'react';
import {
    container,
    innerContainer,
    node,
    connectorBackdrop,
    connectorFill,
    nodeLabel,
    connectorFillFull,
    nodeComplete,
    nodeDoneIcon,
    disabledLink,
} from './index.style';
import { Link } from 'gatsby';
import { materialFont } from '../../../global.style';

interface Props {
    isActive: boolean;
    label: string;
    isCompleted: boolean;
    isFinal: boolean;
    index: number;
    url: string;
    alt: string;
}

export default function FormProgressNode(props: Props) {
    const { label, index, isFinal, isCompleted, isActive, url, alt } = props;
    return (
        <div css={container}>
            <div css={innerContainer}>
                <div css={[node, ...(isCompleted ? [nodeComplete] : [])]}>
                    {
                        <Link alt={alt} to={url} css={[...(!isCompleted ? [disabledLink] : [])]}>
                            {isCompleted ? <i css={[materialFont, nodeDoneIcon]}>done</i> : <span>{index}</span>}
                        </Link>
                    }
                </div>
                <Link alt={alt} to={url} css={[nodeLabel, ...(!isCompleted ? [disabledLink] : [])]}>
                    {label}
                </Link>
            </div>
            {!isFinal && (
                <>
                    <div css={connectorBackdrop} data-testid="node-connector" />
                    {(isActive || isCompleted) && (
                        <div
                            data-testid={isCompleted ? 'node-connector-complete' : 'node-connector-active'}
                            css={[connectorFill, ...(isCompleted ? [connectorFillFull] : [])]}
                        />
                    )}
                </>
            )}
        </div>
    );
}
