import React from 'react';
import FormProgressNode from './FormProgressNode';
import { container } from './index.style';

interface Props {
    nodes: Array<{
        label: string;
        url: string;
        alt: string;
    }>;
    activeNodeIndex: number;
}

export default function FormProgress(props: Props) {
    const { nodes, activeNodeIndex } = props;
    const formProgressNodes = nodes.map((item, index) => (
        <FormProgressNode
            index={index + 1}
            key={index}
            label={item.label}
            url={item.url}
            isActive={activeNodeIndex === index}
            isCompleted={index < activeNodeIndex}
            isFinal={index === nodes.length - 1}
            alt={item.alt}
        />
    ));
    return (
        <div css={container} role="navigate">
            {formProgressNodes}
        </div>
    );
}
