import { css } from '@emotion/core';

export const inactiveGreyColour = '#dddddd';
export const backdropGreyColour = '#eeeeee';
export const primaryColour = '#584b66';
export const secondaryColour = '#1b2b37';
export const interestedColour = '#5d80ed';
export const navigationColour = '#90889b';
export const activeColour = '#4cb71a';
export const backgroundColour = '#FAFBFC';

export const primaryFont = 'Source Sans Pro';

const breakpoints = [576, 768, 992, 1200];

export enum ScreenWidth {
    XS,
    SM,
    MD,
    LG,
}

export const breakpoint = (screenWidth: ScreenWidth) => {
    switch (screenWidth) {
        case ScreenWidth.XS: {
            return `@media (min-width: ${breakpoints[0]}px)`;
        }
        case ScreenWidth.SM: {
            return `@media (min-width: ${breakpoints[1]}px) `;
        }
        case ScreenWidth.MD: {
            return `@media (min-width: ${breakpoints[2]}px)`;
        }
        case ScreenWidth.LG: {
            return `@media (min-width: ${breakpoints[3]}px)`;
        }
    }
};

export const globalContainer = css({
    html: {
        height: '100%',
        fontFamily: primaryFont,
    },
    body: {
        height: '100%',
        margin: '0px',
        backgroundColor: backgroundColour,
    },
    '#___gatsby': {
        height: '100%',
    },
    'div #gatsby-focus-wrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
});

export const materialFont = css({
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '24px' /* Preferred icon size */,
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',

    /* Support for all WebKit browsers. */
    WebkitFontSmoothing: 'antialiased',
    /* Support for Safari and Chrome. */
    textRendering: 'optimizeLegibility',

    /* Support for Firefox. */
    MozOsxFontSmoothing: 'grayscale',

    /* Support for IE. */
    fontFeatureSettings: 'liga',
});
