import { css } from '@emotion/core';
import { navigationColour } from '../../global.style';

export const container = css({
    position: 'fixed',
    width: '100%',
    bottom: '2.84%',
    textAlign: 'center',
    color: navigationColour,
    'a, a:visited': {
        color: navigationColour,
        textDecoration: 'none',
    },
    fontSize: '16px',
});
