import { css } from '@emotion/core';
import { activeColour, backdropGreyColour, inactiveGreyColour, breakpoint, ScreenWidth } from '../../../global.style';

export const textColour = '#686868';
const connectorWidthSm = '60px';
const connectorWidthMd = '78px';
const connectorWidthLg = '159px';

export const container = css({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    'a:link, a:visited, a:active': {
        textDecoration: 'none',
        color: textColour,
    },
    [breakpoint(ScreenWidth.SM)]: {
        position: 'relative',
        width: '160px',
    },
});

export const innerContainer = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    p: {
        marginTop: '13px',
        marginBottom: '0px',
    },
});

export const nodeDoneIcon = css({
    fontSize: '35px',
    color: 'white',
    marginTop: '15%',
});

export const node = css({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '45px',
    height: '45px',
    marginRight: 'auto',
    marginLeft: 'auto',
    color: textColour,
    borderRadius: '50px',
    border: `2px solid ${inactiveGreyColour}`,
    [breakpoint(ScreenWidth.MD)]: {
        width: '60px',
        height: '60px',
        fontSize: '26px',
    },
    [breakpoint(ScreenWidth.LG)]: {
        width: '67px',
        height: '67px',
        fontSize: '33px',
    },
});

export const nodeComplete = css({
    border: `2px solid ${activeColour}`,
    backgroundColor: activeColour,
    color: 'white',
});

export const disabledLink = css({
    pointerEvents: 'none',
    cursor: 'default',
});

export const connectorBackdrop = css({
    display: 'none',
    [breakpoint(ScreenWidth.SM)]: {
        display: 'block',
        position: 'absolute',
        marginTop: '25px',
        left: '110px',
        width: connectorWidthSm,
        height: '7px',
        backgroundColor: backdropGreyColour,
        borderRadius: '10px',
    },
    [breakpoint(ScreenWidth.MD)]: {
        display: 'block',
        position: 'absolute',
        marginTop: '30px',
        left: '130px',
        width: connectorWidthMd,
        height: '7px',
        backgroundColor: backdropGreyColour,
        borderRadius: '10px',
    },
    [breakpoint(ScreenWidth.LG)]: {
        display: 'block',
        position: 'absolute',
        marginTop: '30px',
        left: '133px',
        width: connectorWidthLg,
        height: '7px',
        backgroundColor: backdropGreyColour,
        borderRadius: '10px',
    },
});

export const connectorFill = css({
    display: 'none',
    [breakpoint(ScreenWidth.SM)]: {
        display: 'block',
        position: 'absolute',
        marginTop: '25px',
        left: '110px',
        width: '20px',
        height: '7px',
        borderRadius: '10px',
        backgroundColor: activeColour,
    },
    [breakpoint(ScreenWidth.MD)]: {
        display: 'block',
        position: 'absolute',
        marginTop: '30px',
        left: '130px',
        width: '30px',
        height: '7px',
        borderRadius: '10px',
        backgroundColor: activeColour,
    },
    [breakpoint(ScreenWidth.LG)]: {
        display: 'block',
        position: 'absolute',
        marginTop: '30px',
        left: '133px',
        width: '50px',
        height: '7px',
        borderRadius: '10px',
        backgroundColor: activeColour,
    },
});

export const connectorFillFull = css({
    display: 'none',
    [breakpoint(ScreenWidth.SM)]: {
        display: 'block',
        left: '110px',
        width: connectorWidthSm,
    },
    [breakpoint(ScreenWidth.MD)]: {
        display: 'block',
        left: '130px',
        width: connectorWidthMd,
    },
    [breakpoint(ScreenWidth.LG)]: {
        display: 'block',
        left: '133px',
        width: connectorWidthLg,
    },
});

export const nodeLabel = css({
    textAlign: 'center',
    color: textColour,
    fontSize: '13px',
    marginTop: '13px',
    [breakpoint(ScreenWidth.MD)]: {
        fontSize: '20px',
    },
    [breakpoint(ScreenWidth.LG)]: {
        fontSize: '23px',
    },
});
