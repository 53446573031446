export const enTranslation = {
    index: {
        heading: 'Heading',
        title: 'Home',
        powerful: 'POWERFUL',
        learning: 'LEARNING',
        collaboration: 'COLLABORATION',
        description:
            "A comprehensive solution to curriculum planning, monitoring, tracking and reporting the progress of students' learning.",
        getStarted: 'GET STARTED',
        links: {
            getStarted: 'Get Started',
            pricing: 'Pricing',
            faq: 'FAQ',
        },
        images: {
            landingPage: 'Organisation Tool',
        },
        resumeLastSession: 'RESUME LAST SESSION',
        pageDescription: 'DPR Onboarding Home',
    },
    notFoundPage: {
        title: 'Page Not found',
        heading: 'NOT FOUND',
        message: "You just hit a route that doesn't exist... the sadness.",
    },
    signUp: {
        mySchool: {
            title: 'Sign Up: My School',
            search: 'Search by school name, postcode or establishment number',
            heading: "LET'S FIND YOUR SCHOOL",
            description: 'DPR Onboarding Home',
            foundSearchResults: 'Found {{length}} schools matching your query',
        },
    },
    general: {
        next: 'NEXT',
    },
};
