import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Formik } from 'formik';
import { Global } from '@emotion/core';
import { navigate } from 'gatsby';

import Header from '../Header';
import Footer from '../Footer';
import i18next from '../../translations/i18n';
import { container, formProgress } from './index.style';
import { globalContainer } from '../../global.style';
import FormValues from '../../models/FormValues';
import FormProgress from '../FormProgress';

interface Props {
    children: React.ReactNode;
    location: {
        pathname: string;
        origin: string;
    };
}

export default function Layout(props: Props) {
    const { children, location } = props;
    const [initialValues, setInitialValues] = useState<FormValues>({});

    useEffect(() => {
        const formSessionJson = localStorage.getItem('formSession');
        if (formSessionJson) {
            setInitialValues(JSON.parse(formSessionJson).formValues);
        } else {
            navigate('/');
        }
    }, []);

    let activeNodeIndex = 0;
    switch (location.pathname) {
        case '/sign-up/my-school': {
            activeNodeIndex = 1;
            break;
        }
        case '/sign-up/myself': {
            activeNodeIndex = 2;
            break;
        }
    }
    const routes = ['/sign-up/my-school'];
    const nodes = [
        {
            label: 'Choose DPR!',
            url: '/',
            alt: 'Go to home page',
        },
        {
            label: 'Find your school',
            url: '/sign-up/my-school',
            alt: 'Go to Sign-Up: My School',
        },
        {
            label: 'About yourself',
            url: '/',
            alt: 'Go to Sign-Up: About Yourself',
        },
        {
            label: 'Package',
            url: '/',
            alt: 'Go to Sign-Up: Package',
        },
        {
            label: 'Contact information',
            url: '/',
            alt: 'Go to Sign-Up: Contact Information',
        },
    ];

    return (
        <>
            <Global styles={globalContainer} />
            <I18nextProvider i18n={i18next}>
                <Header location={location} />
                {routes.includes(location.pathname) && (
                    <div css={formProgress}>
                        <FormProgress nodes={nodes} activeNodeIndex={activeNodeIndex} />
                    </div>
                )}
                <div css={container}>
                    <main>
                        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => {}}>
                            {children}
                        </Formik>
                    </main>
                </div>
                <Footer />
            </I18nextProvider>
        </>
    );
}
