import { css } from '@emotion/core';
import { breakpoint, ScreenWidth } from '../../global.style';

export const container = css({
    margin: '0 0',
    maxWidth: 1500,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    main: {
        width: '100%',
    },
    [breakpoint(ScreenWidth.SM)]: {
        padding: '0 1.0875rem 1.45rem',
        width: '90%',
    },
    [breakpoint(ScreenWidth.MD)]: {
        padding: '0 1.0875rem 1.45rem',
        width: '85%',
    },
    [breakpoint(ScreenWidth.LG)]: {
        padding: '0 1.0875rem 1.45rem',
        width: '100%',
    },
});

export const formProgress = css({
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoint(ScreenWidth.MD)]: {
        marginTop: '10px',
    },
    [breakpoint(ScreenWidth.LG)]: {
        marginTop: '20px',
    },
});
