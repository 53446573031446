import { css } from '@emotion/core';
import { primaryFont, navigationColour, interestedColour, breakpoint, ScreenWidth } from '../../../global.style';

export const container = css({
    margin: '0rem 10px',
    fontFamily: primaryFont,
    fontWeight: 'bold',
    fontSize: '14px',
    color: navigationColour,
    display: 'flex',
    flexDirection: 'column',
    'a, :link, :visited': {
        color: navigationColour,
        textDecoration: 'none',
    },
    [breakpoint(ScreenWidth.XS)]: {
        margin: '0rem 38px 0px 0px',
        fontSize: '20px',
    },
});

export const underline = css({
    height: '2px',
    width: '85%',
    backgroundColor: interestedColour,
    marginTop: '4px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
});
