import React from 'react';
import { container } from './index.style';

const Footer = () => {
    return (
        <footer css={container}>
            {`© `}
            <a href="https://www.axle.education/">Axle Education</a>
            {` ` + new Date().getFullYear()}
        </footer>
    );
};

export default Footer;
