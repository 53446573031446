import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import { header, innerContainer, links, logo } from './index.style';
import HeaderLink from './HeaderLink';

interface Props {
    location: {
        pathname: string;
    };
}

export default function Header(props: Props) {
    const { location } = props;
    const { t } = useTranslation('', { useSuspense: false });
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <header css={header}>
            <div css={innerContainer}>
                <Link to="/" css={logo}>
                    <Img fluid={data.file.childImageSharp.fluid} alt="DPR" />
                </Link>
                {location.pathname === '/' && (
                    <div css={links} role="navigation">
                        <HeaderLink to={'/'} selected={location.pathname === '/'}>
                            {t('index.links.getStarted')}
                        </HeaderLink>
                        <HeaderLink to={'/pricing'}>{t('index.links.pricing')}</HeaderLink>
                        <HeaderLink to={'/faq'}>{t('index.links.faq')}</HeaderLink>
                    </div>
                )}
            </div>
        </header>
    );
}
